.error {
  position: relative;
  height: calc(100vh - 38rem);
  min-height: 40rem;
  @include tabletP {
    height: calc(100vh - 27.66rem);
  }
  @include tabletL {
    height: calc(100vh - 29.6rem);
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center center;
      object-position: center center;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    color: $primaryWhite;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: 1.5rem;
  }
  &__number {
    font-weight: $fontWeightSemiBold;
    font-size: 13.6rem;
    line-height: 14.1rem;
    @include tabletP {
      font-size: 15.6rem;
      line-height: 15.6rem;
    }
  }
  .container {
    height: 100%;
  }
  h1 {
    margin-bottom: 1rem;
  }
  h5 {
    margin-bottom: 4.9rem;
  }
}
